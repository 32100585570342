// FontAwesome icons

// Use plugins to only use the features we need
// Old import: import { config, dom, library } from 'Vendor/@fortawesome/fontawesome-svg-core'
// Docs: https://fontawesome.com/docs/apis/javascript/plugins
import {
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	register,
	ReplaceElements,
} from 'Vendor/@fortawesome/fontawesome-svg-core/plugins.mjs';

const api = register([
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	ReplaceElements,
])

api.config.autoReplaceSvg = 'nest';
api.config.observeMutations = true;

// Solid icons
// Core
import { faAngleLeft          } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight         } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleRight';
import { faCaretDown          } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp            } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCheck              } from 'Vendor/@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle             } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleArrowUp      } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleArrowUp';
import { faCircleCheck        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleMinus        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleMinus';
import { faCirclePlus         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faCircleXmark        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faMagnifyingGlass    } from 'Vendor/@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faMinus              } from 'Vendor/@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus               } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlus';
import { faQuoteLeft          } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight         } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteRight';
import { faStar               } from 'Vendor/@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfStroke     } from 'Vendor/@fortawesome/free-solid-svg-icons/faStarHalfStroke';
import { faXmark              } from 'Vendor/@fortawesome/free-solid-svg-icons/faXmark';

// Account icons
import { faAsterisk           } from 'Vendor/@fortawesome/free-solid-svg-icons/faAsterisk';
import { faCalendarDays       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCalendarDays';
import { faEnvelope           } from 'Vendor/@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGavel              } from 'Vendor/@fortawesome/free-solid-svg-icons/faGavel';
import { faHeart              } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeart';
import { faTrophy             } from 'Vendor/@fortawesome/free-solid-svg-icons/faTrophy';
import { faUser               } from 'Vendor/@fortawesome/free-solid-svg-icons/faUser';
import { faUserGear           } from 'Vendor/@fortawesome/free-solid-svg-icons/faUserGear';

// Charity categories
import { faChild              } from 'Vendor/@fortawesome/free-solid-svg-icons/faChild';
import { faEarthAfrica        } from 'Vendor/@fortawesome/free-solid-svg-icons/faEarthAfrica';
import { faFutbol             } from 'Vendor/@fortawesome/free-solid-svg-icons/faFutbol';
import { faGraduationCap      } from 'Vendor/@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHandshakeAngle     } from 'Vendor/@fortawesome/free-solid-svg-icons/faHandshakeAngle';
import { faHelicopter         } from 'Vendor/@fortawesome/free-solid-svg-icons/faHelicopter';
import { faIdCard             } from 'Vendor/@fortawesome/free-solid-svg-icons/faIdCard';
import { faLeaf               } from 'Vendor/@fortawesome/free-solid-svg-icons/faLeaf';
import { faList               } from 'Vendor/@fortawesome/free-solid-svg-icons/faList';
import { faPalette            } from 'Vendor/@fortawesome/free-solid-svg-icons/faPalette';
import { faPaw                } from 'Vendor/@fortawesome/free-solid-svg-icons/faPaw';
import { faPlaceOfWorship     } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlaceOfWorship';
import { faStethoscope        } from 'Vendor/@fortawesome/free-solid-svg-icons/faStethoscope';
import { faUsers              } from 'Vendor/@fortawesome/free-solid-svg-icons/faUsers';
import { faWheelchair         } from 'Vendor/@fortawesome/free-solid-svg-icons/faWheelchair';

// Store categories
import { faAppleWhole         } from 'Vendor/@fortawesome/free-solid-svg-icons/faAppleWhole';
import { faCalculator         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCalculator';
import { faGamepad            } from 'Vendor/@fortawesome/free-solid-svg-icons/faGamepad';
import { faGift               } from 'Vendor/@fortawesome/free-solid-svg-icons/faGift';
import { faLightbulb          } from 'Vendor/@fortawesome/free-solid-svg-icons/faLightbulb';
import { faPlane              } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlane';
import { faShirt              } from 'Vendor/@fortawesome/free-solid-svg-icons/faShirt';
import { faSterlingSign       } from 'Vendor/@fortawesome/free-solid-svg-icons/faSterlingSign';
import { faStore              } from 'Vendor/@fortawesome/free-solid-svg-icons/faStore';
import { faTree               } from 'Vendor/@fortawesome/free-solid-svg-icons/faTree';
import { faTv                 } from 'Vendor/@fortawesome/free-solid-svg-icons/faTv';

// Navigation
import { faBars               } from 'Vendor/@fortawesome/free-solid-svg-icons/faBars';
import { faCircleInfo         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faHouse              } from 'Vendor/@fortawesome/free-solid-svg-icons/faHouse';
import { faPencil             } from 'Vendor/@fortawesome/free-solid-svg-icons/faPencil';
import { faRightFromBracket   } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faRightToBracket     } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightToBracket';

// Compare
import { faBicycle            } from 'Vendor/@fortawesome/free-solid-svg-icons/faBicycle';
import { faCar                } from 'Vendor/@fortawesome/free-solid-svg-icons/faCar';
import { faHeartPulse         } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeartPulse';
import { faLaptop             } from 'Vendor/@fortawesome/free-solid-svg-icons/faLaptop';
import { faMotorcycle         } from 'Vendor/@fortawesome/free-solid-svg-icons/faMotorcycle';
import { faSuitcase           } from 'Vendor/@fortawesome/free-solid-svg-icons/faSuitcase';
import { faTruck              } from 'Vendor/@fortawesome/free-solid-svg-icons/faTruck';
import { faWrench             } from 'Vendor/@fortawesome/free-solid-svg-icons/faWrench';

// Other
import { faArrowTrendUp       } from 'Vendor/@fortawesome/free-solid-svg-icons/faArrowTrendUp';
import { faBasketShopping     } from 'Vendor/@fortawesome/free-solid-svg-icons/faBasketShopping';
import { faBriefcase          } from 'Vendor/@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBullhorn           } from 'Vendor/@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCartShopping       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCartShopping';
import { faCircleExclamation  } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleQuestion     } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleQuestion';
import { faClock              } from 'Vendor/@fortawesome/free-solid-svg-icons/faClock';
import { faCloudArrowUp       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCloudArrowUp';
import { faCopy               } from 'Vendor/@fortawesome/free-solid-svg-icons/faCopy';
import { faCreditCard         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCreditCard';
import { faFileLines          } from 'Vendor/@fortawesome/free-solid-svg-icons/faFileLines';
import { faFilter             } from 'Vendor/@fortawesome/free-solid-svg-icons/faFilter';
import { faFire               } from 'Vendor/@fortawesome/free-solid-svg-icons/faFire';
import { faGear               } from 'Vendor/@fortawesome/free-solid-svg-icons/faGear';
import { faHourglassHalf      } from 'Vendor/@fortawesome/free-solid-svg-icons/faHourglassHalf';
import { faMobileScreenButton } from 'Vendor/@fortawesome/free-solid-svg-icons/faMobileScreenButton';
import { faPhone              } from 'Vendor/@fortawesome/free-solid-svg-icons/faPhone';
import { faPiggyBank          } from 'Vendor/@fortawesome/free-solid-svg-icons/faPiggyBank';
import { faPlay               } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlay';
import { faQrcode             } from 'Vendor/@fortawesome/free-solid-svg-icons/faQrcode';
import { faRotate             } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotate';
import { faScissors           } from 'Vendor/@fortawesome/free-solid-svg-icons/faScissors';
import { faSpinner            } from 'Vendor/@fortawesome/free-solid-svg-icons/faSpinner';
import { faTags               } from 'Vendor/@fortawesome/free-solid-svg-icons/faTags';
import { faUpDown             } from 'Vendor/@fortawesome/free-solid-svg-icons/faUpDown';
import { faUpRightFromSquare  } from 'Vendor/@fortawesome/free-solid-svg-icons/faUpRightFromSquare';

api.library.add({
	// Core
	faAngleLeft,
	faAngleRight,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCircle,
	faCircleArrowUp,
	faCircleCheck,
	faCircleMinus,
	faCirclePlus,
	faCircleXmark,
	faMagnifyingGlass,
	faMinus,
	faPlus,
	faQuoteLeft,
	faQuoteRight,
	faStar,
	faStarHalfStroke,
	faXmark,

	// Account icons
	faAsterisk,
	faCalendarDays,
	faEnvelope,
	faGavel,
	faHeart,
	faTrophy,
	faUser,
	faUserGear,
	
	// Charity categories
	faChild,
	faEarthAfrica,
	faFutbol,
	faGraduationCap,
	faHandshakeAngle,
	faHelicopter,
	faIdCard,
	faLeaf,
	faList,
	faPalette,
	faPaw,
	faPlaceOfWorship,
	faStethoscope,
	faUsers,
	faWheelchair,

	// Store categories
	faAppleWhole,
	faCalculator,
	faGamepad,
	faGift,
	faLightbulb,
	faPlane,
	faShirt,
	faSterlingSign,
	faStore,
	faTree,
	faTv,

	// Navigation
	faBars,
	faCircleInfo,
	faHouse,
	faPencil,
	faRightFromBracket,
	faRightToBracket,

	// Compare
	faBicycle,
	faCar,
	faHeartPulse,
	faLaptop,
	faMotorcycle,
	faSuitcase,
	faTruck,
	faWrench,

	// Other
	faArrowTrendUp,
	faBasketShopping,
	faBriefcase,
	faBullhorn,
	faCartShopping,
	faCircleExclamation,
	faCircleQuestion,
	faClock,
	faCloudArrowUp,
	faCopy,
	faCreditCard,
	faFileLines,
	faFilter,
	faFire,
	faGear,
	faHourglassHalf,
	faMobileScreenButton,
	faPhone,
	faPiggyBank,
	faPlay,
	faQrcode,
	faRotate,
	faScissors,
	faSpinner,
	faTags,
	faUpDown,
	faUpRightFromSquare,
});

// Regular icons
import { faCircle      as farCircle      } from 'Vendor/@fortawesome/free-regular-svg-icons/faCircle';
import { faCircleCheck as farCircleCheck } from 'Vendor/@fortawesome/free-regular-svg-icons/faCircleCheck';
import { faGem         as farGem         } from 'Vendor/@fortawesome/free-regular-svg-icons/faGem';
import { faStar        as farStar        } from 'Vendor/@fortawesome/free-regular-svg-icons/faStar';
import { faThumbsDown  as farThumbsDown  } from 'Vendor/@fortawesome/free-regular-svg-icons/faThumbsDown';
import { faThumbsUp    as farThumbsUp    } from 'Vendor/@fortawesome/free-regular-svg-icons/faThumbsUp';

api.library.add({
	farCircleCheck,
	farCircle,
	farGem,
	farStar,
	farThumbsDown,
	farThumbsUp,
})

// Brand icons
import { faApple             } from 'Vendor/@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook          } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF         } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faInstagram         } from 'Vendor/@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn        } from 'Vendor/@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter           } from 'Vendor/@fortawesome/free-brands-svg-icons/faTwitter';
import { faWhatsapp          } from 'Vendor/@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faYoutube           } from 'Vendor/@fortawesome/free-brands-svg-icons/faYoutube';

api.library.add({
	faApple,
	faFacebook,
	faFacebookF,
	faFacebookMessenger,
	faInstagram,
	faLinkedinIn,
	faTwitter,
	faWhatsapp,
	faYoutube,
});

// Add our own icons
import { faGayl      } from 'Scripts/common/icons/faGayl';
import { faChromeExt } from 'Scripts/common/icons/faChromeExt';
import { faChromePin } from 'Scripts/common/icons/faChromePin';

api.library.add({faGayl, faChromeExt, faChromePin});

// Replace and watch
api.dom.i2svg();
api.dom.watch();